<template>
<div class="mt-12">
  <v-container class="mt-12 text-center">
    <v-img class="mb-12 mx-auto" src="https://examenselectivitat.cat:3000/api/images/other/love.png" max-width="126px"></v-img>
    <h2>Gràcies per la teva donació, estem molt agraïts. <br> Amb el teu suport podem tirar aquest projecte endavant!</h2>
  </v-container>
</div>
</template>

<script>
export default {
  name: "Success"
}
</script>

<style scoped>

</style>
